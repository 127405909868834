
import Heders from "./Page/heders";
import SectionContentBody from './Page/SectionContentBody'
import Footer from "./Page/Footer";

function App() {
  return (
    <div >
        <Heders/>
        <SectionContentBody/>
        <Footer/>
    </div>
  );
}

export default App;
